import { instanceApi } from './instance';

// User login into the system after successful authorization through one of the providers
export const ssoLogin = async (ssoProviderName: string, token: string): Promise<void> => {
  const response = await instanceApi.post(`api/sso/login?name=${ssoProviderName}`, { token });
  instanceApi.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`;
  localStorage.setItem('token-creditpush', response.data.accessToken);
};

// Get sso authorization key for web components
export const getAuthorizationKeyForWebComponents = (): Promise<string> => instanceApi.get('api/sso/wc-key');
