
import { startFlow, getCompletedFlows } from '@/api/flowSession';
import { actualUrl } from '@/api/instance';
import { ssoLogin } from '@/api/ssoAuthorization';
import { getNextPage } from '@/utils/redirectUtils';
import { getUserSettings } from '@/utils/updateInfo';
import { Component, Vue } from 'vue-property-decorator';
import { mapMutations } from 'vuex';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
// import { loadHubSpotChat } from '@/hubSpotChat';

@Component({
  components: {
    BaseButton,
  },
  methods: {
    ...mapMutations({
      setUserIdAndAccountId: 'user/setUserIdAndAccountId',
      rewriteUserDataFromResponse: 'user/rewriteUserDataFromResponse',
      rewriteLendioDataFromResponse: 'lendio/rewriteLendioDataFromResponse',
    }),
  },
})
export default class GoogleAuthComponent extends Vue {
  public setUserIdAndAccountId!: (n: any) => void;
  public rewriteUserDataFromResponse!: (n: any) => void;
  public rewriteLendioDataFromResponse!: (n: any) => void;

  isPreloaderShow = false;
  ssoProviderName = 'Google';

  get buttonText(): string {
    return this.$route.name === 'LoginPage' ? 'Continue with Google' : 'Sign up with Google';
  }

  signUpGoogle(): void {
    const popupWidth = 700;
    const popupHeight = 800;
    const left = window.screen.width / 2 - popupWidth / 2;
    const top = window.screen.height / 2 - popupHeight / 2;
    const url = `${actualUrl()}service/api/sso/auth?name=${this.ssoProviderName}`;
    window.open(
      url,
      '_blank',
      `left=${left},top=${top},height=${popupHeight},width=${popupWidth},status=yes, toolbar=no, menubar=no, location=no, addressbar=no`,
    );
  }
  async authConnectionListener(event: any): Promise<void> {
    const token = event.data.code;
    if (!token) return;

    try {
      this.isPreloaderShow = true;
      await ssoLogin(this.ssoProviderName, token);
      await getUserSettings();

      // const { data } = await activeFlow();
      const currentFlow = this.$store.getters['flowStages/getCurrentFlow'];

      console.log('currentFlow', currentFlow)

      if (currentFlow) {
        await startFlow(currentFlow);
        this.$router.push({ name: getNextPage() });
      } 
      else {
        this.$router.push({ name: getNextPage() });
      }

      const { data: completedFlows } = await getCompletedFlows();
      this.$store.commit('flowStages/setCompletedFlowsArr', completedFlows)
    } catch (error: any) {
      this.popupError(error.response.data.message);
    }
    // await loadHubSpotChat();
    this.isPreloaderShow = false;
  }
  popupError(error: string): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: error,
    });
  }

  created(): void {
    window.addEventListener('message', this.authConnectionListener);
  }
  beforeDestroy(): void {
    window.removeEventListener('message', this.authConnectionListener);
  }
}
