
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class TimerRedirect extends Vue {
  @Prop({ default: '' }) targetPage!: string;

  timerBeforUserRedirect: null | number = null;
  timerForUser = 10;

  get pageNameForUser(): string {
    return this.targetPage === 'LoginPage' ? 'login' : 'registration';
  }

  startTimer(): void {
    this.timerBeforUserRedirect = setInterval(() => {
      this.timerForUser -= 1;
      if (this.timerForUser === 0) {
        this.$router.push({ name: this.targetPage });
      }
    }, 1000);
  }
  mounted(): void {
    this.startTimer();
  }
  beforeDestroy(): void {
    if (this.timerBeforUserRedirect) clearTimeout(this.timerBeforUserRedirect);
  }
}
